.container_cust {
  max-width: 1350px;
  margin: auto;
  padding: 0 20px;
  width: 100%;
}
.Toastify__toast--info {
  background: #007bff !important;
}
.gen_btn {
  display: inline-block;
  padding: 13px 15px;
  min-width: 226px;
  text-decoration: none;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border: 1px solid;
  cursor: pointer;
}
.navbar-light .navbar-toggler-icon {
  background-image: url(../../images/toogle.png);
  width: 26px;
  height: 18px;
  margin-top: 20px;
}
.navi_gation li.nav-item a:hover {
  color: #fff !important;
}
.navbar-nav .nav-item .medium-icon {
  background-image: url(../../images/medium.svg);
  width: 22px;
  height: 20px;
  margin-top: 8px;
  display: inline-block;
  background-repeat: no-repeat;
}
.navbar-nav .nav-item {
  list-style-type: none;
}
.navbar-nav .nav-item .medium-icon:hover {
  background-image: url(../../images/medium-hov.svg);
  width: 22px;
  height: 20px;
  margin-top: 8px;
  display: inline-block;
  background-repeat: no-repeat;
}
.btn_white_link .gen_link a {
  color: #ffffff;
  margin: 0 10px;
  padding: 12px 25px;
  border-radius: 5px;
  line-height: 15px;
  font-size: 12px;
  background: #37419f;
}
.inner_banner a {
  margin: 15px 10px;
}
.btn_white_link .gen_link a:hover {
  background: #fff;
  color: #37419f;
}
.btn_orange {
  background: #37419f;
  color: #fff;
  border-color: #37419f;
}

.address {
  color: #fff;
}

.btn_orange:hover {
  background: #fff;
  color: #37419f;
}
.btn_white {
  background: transparent;
  color: #fff;
  border: 1px solid transparent;
}

.addressh {
  color: #fff;
  border: 2px solid #fff;
  padding: 8px 18px;
  border-radius: 5px;
  font-size: 18px;
  display: inline-block;
  background: black;
  cursor: pointer;
}
ul.navbar-nav.icon-nav {
  line-height: 20px;
}
.nav-item.d-tab {
  padding: 15px 0;
  display: flex;
}
.nav-item.d-tab .navbar-nav.icon-nav {
  display: flex;
  flex-direction: inherit;
  align-items: center;
}
.hea_der {
  background-image: url(../../images/nav-bg.jpg);
  background-repeat-y: repeat;
  padding: 6px 0;
  width: 100%;
  background-repeat-x: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.hea_der:after {
  background-image: url(../../images/nav-before.png);
  width: 100%;
  background-repeat-x: repeat;
  background-size: 100% 100%;
  position: absolute;
  height: 5px;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0px;
}
.inner_header {
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
}
.navi_gation {
  text-align: right;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}
.navbar-nav svg.MuiSvgIcon-root {
  margin-top: 1px;
  margin-right: 10px;
  position: relative;
  right: 5px;
  font-size: 28px;
}
.navbar-nav li {
  padding: 0 5px;
}
.navbar-nav li a {
  color: #7bf5fb !important;
  padding: 5px 5px !important;
  transition: all 0.5s ease;
}
.footer-social .navbar-nav li a:hover {
  color: #fff !important;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: transparent !important;
  justify-content: space-between !important;
}

.navbar-nav.white-ul li a.nav-link {
  color: #fff !important;
  font-size: 18px;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
}
.navbar-nav.white-ul li a.nav-link:hover {
  color: #7bf5fb !important;
}
.profile-box {
  background-image: url("../../assets/profile-frame.png");
  background-repeat: no-repeat;
  min-height: 60px;
  width: 60px;
  background-size: 100% 100%;
  border: 0px;
  transition: all 0.5s ease;
  color: #7bf5fb;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0px;
}

.profile-box:hover {
  /* background-image: url("../../assets/profile-frame-hover.png"); */
  /* color: #fff; */
}

.profile-box svg {
  font-size: 24px;
}
.yellow_btn {
  background-image: url(../../images/btn-bg.png);
  width: 200px;
  height: 60px;
  color: #7bf5fb;
  padding: 12px 0px;
  line-height: 20px;
  font-size: 18px;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  text-transform: capitalize;
  font-weight: 700;
  background-size: 100% 100%;
}
.yellow_btn:hover {
  background-image: url(../../images/btn_transparent.png);
  transition: all 0.5s ease;
  background-size: 100% 100%;
  color: #7bf5fb;
}
.hea_der .btn_orange:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}
.navi_gation .gen_btn.btn_white {
  margin-right: 10px;
}
.navi_gation .gen_btn.btn_white {
  display: none;
}
.hea_der .container_cust {
  padding: 0 4px;
}

p {
  font-weight: 300 !important;
}

/* modal */
.paper {
  position: absolute;
  width: 100%;
  max-width: 400px;
  height: auto;
  background-color: transparent;
  border: 0px solid #000;
  box-shadow: 5;
  padding: 2px, 4px, 3px;
  border-radius: 20px;
  text-align: center;
  padding: 0px 0 28px 0;
  height: auto;
  transform: none !important;
  left: 0 !important;
  right: 0;
  margin: auto;
  top: 25% !important;
}
.paper_button {
  background: #37419f;
  color: #fff;
  border: 1px solid #37419f;
  font-size: 16px;
  padding: 9px 50px;
  font-family: Roboto;
  text-transform: uppercase;
  border-radius: 6px;
  margin: auto;
  cursor: pointer;
}
.navbar-light .navbar-toggler {
  color: inherit;
  border-color: inherit;
  background: transparent;
  border: 0;
}
.paper_h2 {
  background: #37419f;
  color: #fff;
  padding: 14px 0;
  border-radius: 16px 16px 0 0;
}
.paper_input {
  width: 95%;
  display: inline-block;
  padding: 8px 3px;
  border-radius: 6px;
  border: 1px solid #37419f;
  margin-top: 18px;
}
/* modal */

.right-tophead {
  display: flex;
  justify-content: flex-end;
}

.navbar-nav li a span {
  font-size: 22px;
}
/* .navbar-nav li a:hover{
	color: #14c6cf !important;
} */
/* @media (max-width: 767px){ */

.d-mobile {
  display: none;
}
.right-tophead .navbar-nav.icon-nav {
  display: none;
}
@media (max-width: 1024px) {
  .collapse.navbar-collapse {
    justify-content: left;
    display: none;
    width: 100%;
    text-align: left;
  }
  .collapse.navbar-collapse.show {
    display: block !important;
  }

  .navi_gation {
    grid-gap: 4px;
  }

  .logo {
    width: 155px;
    margin: inherit !important;
  }

  .logo {
    width: 155px;
    margin: 0 auto;
  }
  .navbar-nav li {
    padding: 0 5px;
  }
}
@media (max-width: 992px) {
  header .inner_header {
    display: grid !important;
    grid-template-columns: 220px auto !important;
    align-items: center;
    padding-left: 9px;
    width: 100%;
  }

  div#navbarSupportedContent {
    position: absolute;
    background: #13141e;
    right: 0px;
    width: 100%;
    text-align: left;
    z-index: 999;
    padding: 20px;
    margin-top: 32px;
  }
  .navbar.navbar-expand-lg.navbar-light.bg-light {
    display: block !important;
    margin-top: 0px;
    width: 100%;
    position: inherit;
    float: right !important;
  }
  .right-tophead {
    display: block;
    padding-bottom: 15px;
  }
  .nav-item.d-tab .navbar-nav.icon-nav {
    display: none;
  }
  .navbar-nav.white-ul {
    display: block;
  }
  .right-tophead .navbar-nav.icon-nav {
    display: flex;
    flex-direction: inherit;
    margin-top: 15px;
  }
  .hea_der:after {
    background-image: url(../../images/nav-beforetab.png);
  }
}
@media (max-width: 767px) {
  .navi_gation .gen_bttn {
    min-width: 100px !important;
  }
  /*.navi_gation .gen_bttn.btn_white.connect_btn.yellow_btn {
		word-break: break-all;
		width: auto;
		line-height: 22px;
		padding: 5px 11px 14px 11px;
		height: 33px !important;
		overflow: hidden;
		background: #fff;
		color: #37419f;
		margin-bottom: 10px;
	}*/
  .navi_gation .gen_bttn {
    min-width: 68px !important;
  }
  .hea_der {
    padding: 0;
  }
  div#navbarSupportedContent {
    margin-top: 26px;
  }
  /*.navbar.navbar-expand-lg.navbar-light.bg-light {
    margin-top: -65px;
}*/
  .hea_der .navbar-nav svg.MuiSvgIcon-root {
    margin-top: -2px;
  }
}
@media (max-width: 640px) {
  .nav-item.d-tab {
    display: none;
  }
  .d-mobile {
    display: block;
    margin-top: 10px;
  }
  div#navbarSupportedContent {
    margin-top: 10px;
  }
  .navbar-light .navbar-toggler-icon {
    margin-top: 0;
  }
  .navbar-nav .nav-item .medium-icon {
    margin-top: 3px;
  }
  .hea_der:after {
    background-image: url(../../images/nav-beforemob.png);
  }
}
@media (max-width: 385px) {
  /* header .inner_header { grid-template-columns: 160px auto !important; display: grid !important;} */
  .navi_gation {
    justify-content: end !important;
  }
}
